import React from "react"
import Layout from "../components/layout"
import { Gallery } from "gatsby-theme-gallery"
import instaIcon from "../images/insta-logo.png"

const Photography = () => (
  <Layout>
    <div style={{ marginTop: '20px', float: 'right' }}>
     {/* <a href='https://society6.com/mareewessels' rel='noreferrer' title='Purchase photos' target='_blank'>
        <button className='btn'>Buy prints</button>
      </a> */}
      <a href='https://www.instagram.com/maree.wessels/' rel='noreferrer' target='_blank' title='Check out his Instagram'>
        <img className='icon' alt='instagram icon' src={instaIcon} />
      </a>
    </div>
    <div className='home-section' style={{ marginTop: '3rem', display: 'block' }}>
      <h2>Wildlife Photography</h2>
      <p>This is a small collection of Maree's photographs.</p>
      <p>He used his camera to capture his love for wildlife. These were all taken at the Kruger National Park, which was very special place to him. Check out his popular <a href='https://www.instagram.com/maree.wessels/' rel='noreferrer' target='_blank' title='Check out his Instagram'>Instagram</a> account for his favourites.{/*, or purchase prints or digital copies of his photographs.*/} </p>
    </div>
    <div style={{ padding: '1rem 5rem' }}>
        <Gallery />
    </div>
 </Layout>
)

export default Photography